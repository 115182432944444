<template>
  <div style="background-color: #fff;
    border-radius: 10px;
    font-size: 14px;">
        <!--数据表格-->
        <el-table
          ref="tableData"
          :data="tableData"
          style="width: 100%;"
          border
          height="calc(100vh - 240px)"
          :stripe="true"
          :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,background:'#f8f8f9',textAlign:'left',}">
          <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          align="center"
          width="60"
          >
          </el-table-column>
          <el-table-column prop="userTypeName" label="分类" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="未入户完成次数"  show-overflow-tooltip>
             <template slot="header">
              未入户完成次数（次）
              <el-tooltip
                class="item"
                effect="dark"
                content="即配置到访不遇和拒绝安检提交几次算工单完成"
                placement="top"
              >
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </template>
            <template slot-scope="{row}">
              <el-select v-model="row.visitWorst" placeholder="请选择" size="mini" filterable @change="(val) => OnChangeWorst(val,row.userType,row.visitDay)">
                <el-option
                  v-for="item in freqList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="到访不遇提交时间间隔" show-overflow-tooltip>
            <template slot-scope="{row}">
              <el-select v-model="row.visitDay" placeholder="请选择" size="mini" @change="(val) => OnChangeDay(val,row.userType,row.visitWorst)">
                <el-option
                  v-for="item in freqDateList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
  </div>
</template>
<script>
import { frequencyList,frequencySet } from "../../RequestPort/dict/cycle";
export default {
  name: 'Frequency',
  data() {
    return {
      tableData: [], //列表对象
      indexMethod:1,
      freqList:[
        {value: '1次',id: 1}, 
        {value: '2次',id: 2}, 
        {value: '3次',id: 3}, 
        {value: '4次',id: 4}, 
        {value: '5次',id: 5}, 
        {value: '6次',id: 6}, 
        {value: '7次',id: 7}, 
        {value: '8次',id: 8}, 
        {value: '9次',id: 9}, 
        {value: '10次',id: 10}, 
      ],
      freqDateList:[
        {value: '0天',id: 0},
        {value: '1天',id: 1}, 
        {value: '2天',id: 2}, 
        {value: '3天',id: 3}, 
        {value: '4天',id: 4}, 
        {value: '5天',id: 5}, 
        {value: '6天',id: 6}, 
        {value: '7天',id: 7}, 
        {value: '8天',id: 8}, 
        {value: '9天',id: 9}, 
        {value: '10天',id: 10}, 
      ],
      // 提交数据
      aaumFrequencyUpdateDTO:{}
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    /**
     * 获取频率数据
     */
    getList(){
      frequencyList().then(res=>{
        this.tableData = res.data
      })
    },
    /**
     * 更改次数
     */
    OnChangeWorst(val,userT,visitDay){
        this.aaumFrequencyUpdateDTO.userType = userT
        this.aaumFrequencyUpdateDTO.visitWorst = val
        this.aaumFrequencyUpdateDTO.visitDay = visitDay
        this.onsubmit()
    },
    /**
     * 提交频率
     */
    OnChangeDay(val,userT,visitWorst) {
      this.aaumFrequencyUpdateDTO.userType = userT
        this.aaumFrequencyUpdateDTO.visitWorst = visitWorst
        this.aaumFrequencyUpdateDTO.visitDay = val
        this.onsubmit()
    },
    /**
     * 更改值提交
     */
    onsubmit(){
      frequencySet(this.aaumFrequencyUpdateDTO).then(e => {
        if (e.code === 200) {
            this.$message.success('编辑成功');
            this.getList();
          } else {
            this.$message.error(e.msg);
          }
      })
    },
    handleSizeChange(val) {
      this.search.size = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.search.current = val
    }
  }
}
</script>

<style scoped>
.table {
  border: 1px solid #ecedf1;
  border-radius: 5px;
  margin-right: 1.5%;
}
.all {
  background-color: white;
  padding-top: 0.5%;
}
.content {
  background: #ffffff;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 70%;
}
.lab {
  margin-left: 2%;
}
.dialog_form {
  margin-left: 10%;
}
.SearchBtn2 {
  margin-left: 1%;
}
</style>
